import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../services/firebase';
import logo from './DocSwapLogo.png';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [acceptedTermsAndPrivacy, setAcceptedTermsAndPrivacy] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Password validation (min 8 chars, 1 letter, 1 number, 1 special char)
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Password must be at least 8 characters long and include at least one letter, one number, and one special character.');
      return;
    }

    // Ensure the terms and privacy checkbox is checked
    if (!acceptedTermsAndPrivacy) {
      setError('You must accept the Terms & Conditions and Privacy Policy to sign up.');
      return;
    }

    setError(''); // Clear error if validations pass

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/profile'); // Redirect to profile page after successful signup
    } catch (error) {
      console.error('Signup error:', error.message);
      setError(error.message); // Show Firebase errors
    }
  };

  return (
    <div style={containerStyle}>
      <div style={formBoxStyle}>
        <img src={logo} alt="DocSwap Logo" style={logoStyle} />
        <h2 style={headingStyle}>Sign Up</h2>
        <form onSubmit={handleSignup} style={formStyle}>
          {error && <p style={errorStyle}>{error}</p>}
          <div>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email" 
              style={inputStyle}
              required
            />
          </div>
          <div>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              style={inputStyle}
              required
            />
          </div>
          <div>
            <label style={checkboxLabelStyle}>
              <input 
                type="checkbox" 
                checked={acceptedTermsAndPrivacy} 
                onChange={(e) => setAcceptedTermsAndPrivacy(e.target.checked)} 
                required 
              />
              I accept the <Link to="/TandC" target="_blank">Terms & Conditions</Link> and <Link to="/privacy" target="_blank">Privacy Policy</Link>
            </label>
          </div>
          <button type="submit" style={buttonStyle}>Sign Up</button>
        </form>
        <p style={linkStyle}>
          Already have an account? 
          <button onClick={() => navigate('/login')} style={linkButtonStyle}>Login</button>
        </p>
      </div>
    </div>
  );
}

// Additional styles
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(to right, #ff69b4, #800080)',
};

const formBoxStyle = {
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const logoStyle = {
  width: '80px',
  marginBottom: '20px',
};

const headingStyle = {
  textAlign: 'center',
  fontSize: '24px',
  color: '#800080',
  marginBottom: '20px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  width: '100%',
};

const inputStyle = {
  padding: '12px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  fontSize: '16px',
  outline: 'none',
  width: '100%',
  boxSizing: 'border-box',
  transition: 'border 0.3s ease',
};

const buttonStyle = {
  padding: '12px 20px',
  backgroundColor: '#800080',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box',
  transition: 'background-color 0.3s ease',
};

const linkStyle = {
  textAlign: 'center',
  marginTop: '15px',
};

const linkButtonStyle = {
  background: 'none',
  border: 'none',
  color: '#800080',
  fontSize: '16px',
  cursor: 'pointer',
};

const errorStyle = {
  color: 'red',
  textAlign: 'center',
  marginBottom: '10px',
};

const checkboxLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: '#555',
};

export default Signup;
