import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../services/firebase';
import logo from './DocSwapLogo.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/profile');
    } catch (error) {
      setError('Incorrect email or password.');
      console.error('Login error:', error.message);
    }
  };

  return (
    <div style={containerStyle}>
      <div style={formBoxStyle}>
        <img src={logo} alt="DocSwap Logo" style={logoStyle} />
        <h2 style={headingStyle}>Login</h2>
        {error && <p style={errorStyle}>{error}</p>}
        <form onSubmit={handleLogin} style={formStyle}>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              style={inputStyle}
            />
          </div>
          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              style={inputStyle}
            />
          </div>
          <button type="submit" style={buttonStyle}>Login</button>
        </form>
        <p style={linkStyle}>
          Don't have an account?{' '}
          <button onClick={() => navigate('/signup')} style={linkButtonStyle}>Sign Up</button>
        </p>
      </div>
    </div>
  );
}

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(to right, #ff69b4, #800080)',
};

const formBoxStyle = {
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const logoStyle = {
  width: '80px',
  marginBottom: '20px',
};

const headingStyle = {
  textAlign: 'center',
  fontSize: '24px',
  color: '#800080',
  marginBottom: '20px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  width: '100%',
};

const inputStyle = {
  padding: '12px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  fontSize: '16px',
  outline: 'none',
  width: '100%',
  boxSizing: 'border-box',
  transition: 'border 0.3s ease',
};

const buttonStyle = {
  padding: '12px 20px',
  backgroundColor: '#800080',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box',
  transition: 'background-color 0.3s ease',
};

const linkStyle = {
  textAlign: 'center',
  marginTop: '15px',
};

const linkButtonStyle = {
  background: 'none',
  border: 'none',
  color: '#800080',
  fontSize: '16px',
  cursor: 'pointer',
};

const errorStyle = {
  color: 'red',
  textAlign: 'center',
  marginBottom: '15px',
};

export default Login;
