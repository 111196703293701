import React from 'react';
import { Link } from 'react-router-dom';

const contactStyle = {
  backgroundColor: '#fff',
  color: '#333',
  fontFamily: "'Roboto', sans-serif",
  padding: '40px 20px',
  textAlign: 'center',
};

const headingStyle = {
  fontSize: '36px',
  fontWeight: 'bold',
  color: '#C33764', // Custom pink color
  marginBottom: '20px',
};

const paragraphStyle = {
  fontSize: '18px',
  lineHeight: '1.6',
  color: '#555',
  marginBottom: '20px',
  maxWidth: '800px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const linkStyle = {
  fontSize: '18px',
  color: '#C33764',
  fontWeight: 'bold',
  textDecoration: 'none',
  display: 'inline-block',
  marginTop: '20px',
};

function Contact() {
  return (
    <div style={contactStyle}>
      <h1 style={headingStyle}>Contact Us</h1>
      <p style={paragraphStyle}>
        If you have any questions, feel free to contact us via email at the link below. We look forward to hearing from you!
      </p>

      {/* Direct Email Link */}
      <a href="mailto:docswap.sup@gmail.com" style={linkStyle}>
        docswap.sup@gmail.com
      </a>

      
    </div>
  );
}

export default Contact;
