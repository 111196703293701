import React from 'react';

const containerStyle = {
  backgroundColor: '#fff',
  color: '#333',
  fontFamily: "'Roboto', sans-serif",
  padding: '40px 20px',
  textAlign: 'left',
  maxWidth: '1000px',
  margin: '0 auto',
};

const headingStyle = {
  fontSize: '36px',
  fontWeight: 'bold',
  color: '#C33764',
  marginBottom: '20px',
};

const subHeadingStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginTop: '30px',
  color: '#333',
};

const paragraphStyle = {
  fontSize: '16px',
  lineHeight: '1.8',
  color: '#555',
  marginBottom: '20px',
};

function TandC() {
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Terms and Conditions</h1>

      <p style={paragraphStyle}>
        Welcome to DocSwap! By using our website, mobile app, and services, you agree to the following Terms and Conditions. Please read them carefully.
      </p>

      <h2 style={subHeadingStyle}>1. General Terms</h2>
      <p style={paragraphStyle}>
        1.1 DocSwap provides a platform for doctors who have graduated to swap internship places across South Africa. By using this service, you agree to adhere to the terms outlined here.
      </p>
      <p style={paragraphStyle}>
        1.2 We reserve the right to modify, update, or discontinue any part of our service without prior notice. Please check this page periodically for updates.
      </p>

      <h2 style={subHeadingStyle}>2. User Responsibilities</h2>
      <p style={paragraphStyle}>
        2.1 You are responsible for maintaining the confidentiality of your account and all activities associated with it.
      </p>
      <p style={paragraphStyle}>
        2.2 You agree to provide accurate, current, and complete information about yourself when registering and using our platform.
      </p>
      <p style={paragraphStyle}>
        2.3 You agree not to use the platform for any illegal or unauthorized purpose.
      </p>

      <h2 style={subHeadingStyle}>3. Privacy Policy</h2>
      <p style={paragraphStyle}>
        3.1 Your privacy is important to us. We comply with South Africa's Protection of Personal Information Act (POPIA) and are committed to protecting your personal information.
      </p>
      <p style={paragraphStyle}>
        3.2 The information you provide to DocSwap will only be used for the purposes of the service and will not be shared with third parties without your consent, except as required by law.
      </p>

      <h2 style={subHeadingStyle}>4. Intellectual Property</h2>
      <p style={paragraphStyle}>
        4.1 All content on DocSwap, including but not limited to text, graphics, logos, images, and software, is the property of DocSwap or its licensors and is protected by intellectual property laws.
      </p>
      <p style={paragraphStyle}>
        4.2 You agree not to copy, distribute, or modify any content from the platform without prior written consent from DocSwap.
      </p>

      <h2 style={subHeadingStyle}>5. Limitation of Liability</h2>
      <p style={paragraphStyle}>
        5.1 DocSwap is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our platform.
      </p>
      <p style={paragraphStyle}>
        5.2 We make no representations or warranties regarding the accuracy, reliability, or availability of our services.
      </p>

      <h2 style={subHeadingStyle}>6. Termination</h2>
      <p style={paragraphStyle}>
        6.1 DocSwap reserves the right to terminate or suspend your account at any time, without notice, for violation of these Terms and Conditions or any applicable laws.
      </p>

      <h2 style={subHeadingStyle}>7. Governing Law</h2>
      <p style={paragraphStyle}>
        7.1 These Terms and Conditions are governed by the laws of South Africa. Any disputes arising from the use of DocSwap will be resolved in the appropriate South African courts.
      </p>

      <h2 style={subHeadingStyle}>8. Contact Information</h2>
      <p style={paragraphStyle}>
        8.1 If you have any questions regarding these Terms and Conditions, please contact us at: <a href="mailto:docswap.sup@gmail.com">docswap.sup@gmail.com</a>
      </p>
      <h2 style={subHeadingStyle}>9. User Interactions and Liability Disclaimer</h2>
      <p style={paragraphStyle}>
        9.1 By using the DocSwap platform, you acknowledge that DocSwap is a platform that connects users for the purpose of facilitating appointments and other related services. However, DocSwap is not responsible for any interactions, communications, or transactions that occur between users.</p>
  <p style={paragraphStyle}>9.2 You agree that any contact, engagement, or transaction you have with another user is solely between you and the other user. DocSwap does not verify the accuracy or legitimacy of any information shared by users, and therefore, is not liable for any disputes, misunderstandings, or damages that may arise as a result of such interactions.</p>
  <p style={paragraphStyle}>9.3 DocSwap strongly recommends that all users exercise caution and perform due diligence before engaging with other users. If any issues arise between you and another user, it is your responsibility to resolve them directly. DocSwap will not mediate or intervene in such matters.</p>
  <p style={paragraphStyle}>9.4 By continuing to use the service, you acknowledge and agree to this disclaimer and agree that DocSwap will not be held liable for any issues arising from interactions between users.</p>

      
    </div>
  );
}

export default TandC;
