import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import Home from './pages/home';
import Profile from './pages/profile';
import Login from './pages/login';
import Signup from './pages/signup';
import TandC from './pages/TandC';
import Privacy from './pages/Privacy';
import About from './pages/About';
import Contact from './pages/Contact';
import { auth } from './services/firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false); // Set loading to false once user state is updated
    });
    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    window.location.href = "/login"; 
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#C33764',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
  };

  const logoStyle = {
    height: '60px',
    width: 'auto',
    marginRight: '20px',
  };

  const ProtectedRoute = ({ children }) => {
    if (loading) return <div style={{ textAlign: 'center', marginTop: '20px' }}>Loading...</div>; // Show loading spinner
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };
  const footerLinkStyle = {
    color: '#C33764', // White text color
    textDecoration: 'none', // No underlines
    fontSize: '14px',
    margin: '0 10px', // Spacing between links
    transition: 'color 0.3s ease', // Smooth color transition
  };
  
  footerLinkStyle[':hover'] = {
    color: '#4CAF50', // Change link color on hover (subtle green)
  };
  

  return (
    <Router>
      <div>
        {user && (
          <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', backgroundColor: '#f4f4f4' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/DocSwapLogo.png" alt="DocSwap Logo" style={logoStyle} />
              <Link to="/home" style={{ marginRight: '20px', textDecoration: 'none', color: '#000' }}>Home</Link>
              <Link to="/profile" style={{ marginRight: '20px', textDecoration: 'none', color: '#000' }}>Profile</Link>
              
            </div>
            <button onClick={handleLogout} style={buttonStyle}>
              Logout
            </button>
          </nav>
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/TandC" element={<TandC />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="*" element={<Navigate to={user ? "/home" : "/login"} />} />
        </Routes>
        <footer style={{ padding: '20px', textAlign: 'center', marginTop: '20px', backgroundColor: '#f4f4f4' }}>
        <div style={{ marginBottom: '15px' }}>
    <Link to="/About" style={footerLinkStyle}>About Us</Link>
    <span style={{ margin: '0 10px', color: '#bbb' }}>|</span>
    <Link to="/Contact" style={footerLinkStyle}>Contact Us</Link>
  </div>

  <div style={{ marginBottom: '15px' }}>
    <Link to="/TandC" style={footerLinkStyle}>Terms & Conditions</Link>
    <span style={{ margin: '0 10px', color: '#bbb' }}>|</span>
    <Link to="/privacy" style={footerLinkStyle}>Privacy Policy</Link>
  </div>

  <p style={{
    fontSize: '14px',
    color: '#bbb',
    marginTop: '15px',
  }}>© DocSwap 2024</p>
      </footer>
      </div>
    </Router>
  );
}

export default App;
