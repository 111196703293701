import React, { useEffect, useState } from 'react';
import { db, auth } from '../services/firebase';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    name: '',
    university: '',
    degree: '',
    internshipLocation: '',
    preferredLocation: '',
    email: '',
    phone: '',
    showEmail: false,
    showPhone: false,
  });
  const [user, setUser] = useState(null);

  // Auth state change listener to check for logged-in user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchProfile(currentUser.uid);
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, [navigate]);

  // Fetch the user's profile from Firestore
  const fetchProfile = async (userId) => {
    try {
      const profileDoc = await getDoc(doc(db, 'profiles', userId));
      if (profileDoc.exists()) {
        setProfile(profileDoc.data());
      } else {
        console.log("No profile found for this user.");
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  // Handle profile data deletion
  const handleDeleteProfile = async () => {
    if (window.confirm("Are you sure you want to clear your profile from the system? Enter and save profile details if you wish to be added back.")) {
      try {
        await deleteDoc(doc(db, 'profiles', user.uid));
        alert("Profile deleted successfully.");
        setProfile({
          name: '',
          university: '',
          degree: '',
          internshipLocation: '',
          preferredLocation: '',
          email: '',
          phone: '',
          showEmail: false,
          showPhone: false,
        });
      } catch (error) {
        console.error("Error deleting profile:", error);
        alert("An error occurred while deleting the profile. Please try again.");
      }
    }
  };

  // Handle account deletion
  const handleDeleteAccount = () => {
    const confirmed = window.confirm("Are you sure you want to delete your account? This cannot be undone.");
    if (confirmed && user) {
      deleteDoc(doc(db, 'profiles', user.uid)) // Delete profile document
        .then(() => auth.currentUser.delete()) // Delete the authenticated user
        .then(() => {
          alert("Account deleted successfully!");
          navigate('/signup'); // Redirect to signup page after account deletion
        })
        .catch(error => console.error("Error deleting account: ", error));
    }
  };

  // Handle input change for profile fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  // Handle checkbox change for visibility settings
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: checked,
    }));
  };

  // Save profile data to Firestore
 // Save profile data to Firestore
const handleSaveProfile = async () => {
  // Check if at least one contact option (email or phone) is selected
  if (!profile.showEmail && !profile.showPhone) {
    alert("Please choose at least one contact option (email or phone) to display.");
    return;
  }

  if (user) {
    try {
      await setDoc(doc(db, 'profiles', user.uid), profile);
      alert("Profile saved successfully.");
    } catch (error) {
      console.error("Error saving profile:", error);
      alert("An error occurred while saving the profile. Please try again.");
    }
  }
};


  // Inline style objects for modern design
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '1200px',
    margin: 'auto',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd',
  };

  const labelStyle = {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333',
    width: '250px',
    marginBottom: '15px',
  };

  const inputStyle = {
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '15px',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    transition: 'border 0.3s ease',
  };

  const inputFocusStyle = {
    border: '1px solid #5c6bc0',
  };

  const buttonStyle = {
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: '#5c6bc0',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const deleteButtonStyle = {
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: '15px',
    marginRight: '10px',
    transition: 'background-color 0.3s ease',
  };

  const deleteButtonsContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    marginTop: '20px',
  };
  const textButtonStyle = {
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: 'transparent',
    color: '#5c6bc0',
    border: '1px solid #5c6bc0',
    borderRadius: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'background-color 0.3s ease',
  };
  const provinces = [
    "Eastern Cape", 
    "Free State", 
    "Gauteng", 
    "KwaZulu-Natal", 
    "Limpopo", 
    "Mpumalanga", 
    "Northern Cape", 
    "North West", 
    "Western Cape"
  ];
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', backgroundColor: '#f5f5f5' }}>
      <div style={containerStyle}>
        <h2 style={{ textAlign: 'center', fontSize: '24px', color: '#333' }}>Profile</h2>

        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={labelStyle}>Name:</label>
          <input
            type="text"
            name="name"
            value={profile.name}
            onChange={handleInputChange}
            style={inputStyle}
            
          />
        </div>

        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={labelStyle}>University:</label>
          <input
            type="text"
            name="university"
            value={profile.university}
            onChange={handleInputChange}
            style={inputStyle}
         
          />
        </div>

        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={labelStyle}>Degree:</label>
          <input
            type="text"
            name="degree"
            value={profile.degree}
            onChange={handleInputChange}
            style={inputStyle}
           
          />
        </div>

        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
  <label style={labelStyle}>Current Internship Location:</label>
  <select
    name="internshipLocation"
    value={profile.internshipLocation}
    onChange={handleInputChange}
    style={inputStyle}
  >
    <option value="">Select a Province</option>
    {provinces.map((province) => (
      <option key={province} value={province}>{province}</option>
    ))}
  </select>
</div>

<div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
  <label style={labelStyle}>Preferred Location:</label>
  <select
    name="preferredLocation"
    value={profile.preferredLocation}
    onChange={handleInputChange}
    style={inputStyle}
  >
    <option value="">Select a Province</option>
    {provinces.map((province) => (
      <option key={province} value={province}>{province}</option>
    ))}
  </select>
</div>

        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={labelStyle}>Email:</label>
          <input
            type="email"
            name="email"
            value={profile.email}
            onChange={handleInputChange}
            style={inputStyle}
           
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ fontSize: '14px' }}>
            <input
              type="checkbox"
              name="showEmail"
              checked={profile.showEmail}
              onChange={handleCheckboxChange}
              style={{ marginLeft: '10px' }}
            />
            Show email to others
          </label>
        </div>

        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label style={labelStyle}>Phone:</label>
          <input
            type="text"
            name="phone"
            value={profile.phone}
            onChange={handleInputChange}
            style={inputStyle}
           
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ fontSize: '14px' }}>
            <input
              type="checkbox"
              name="showPhone"
              checked={profile.showPhone}
              onChange={handleCheckboxChange}
              style={{ marginLeft: '10px' }}
            />
            Show phone to others
          </label>
        </div>

        <button style={buttonStyle} onClick={handleSaveProfile}>Save Profile</button>

<p style={{ margin: '10px 0', color: 'gray', fontSize: '14px', textAlign: 'center' }}>
  Please ensure you have added and saved your profile details to view other users' profiles.
</p>

<button onClick={() => navigate('/home')} style={textButtonStyle}>
  To view other profiles, go to Home
</button>

       
        <div style={deleteButtonsContainerStyle}>
          <button style={deleteButtonStyle} onClick={handleDeleteProfile}>Clear Profile</button>
          <button style={deleteButtonStyle} onClick={handleDeleteAccount}>Delete Account</button>
        </div>
      </div>
    </div>
  );
}

export default Profile;