import React, { useEffect, useState } from 'react';
import { db, auth } from '../services/firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

function Home() {
  const [profiles, setProfiles] = useState([]);
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Fetch additional user data from Firestore
          const userDocRef = doc(db, 'profiles', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            setUser({ uid: currentUser.uid, ...userDoc.data() });
          } else {
            console.log('User document does not exist');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        navigate('/login');
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);
  
  

  useEffect(() => {
    if (user) {
      const fetchProfiles = async () => {
        try {
          const profilesCollection = collection(db, 'profiles');
          const profilesQuery = query(profilesCollection, orderBy('name'));
          const profilesSnapshot = await getDocs(profilesQuery);

          const profilesList = profilesSnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((profile) => profile.id !== user.uid);

            profilesList.sort((a, b) => {
              const userLocation = user.internshipLocation;
              const userPreferredLocation = user.preferredLocation;
            
              const aMatches =
                (a.internshipLocation === userLocation || a.preferredLocation === userLocation) ? 1 : 0;
              const bMatches =
                (b.internshipLocation === userLocation || b.preferredLocation === userLocation) ? 1 : 0;
            
              // Sort profiles with matches higher
              if (aMatches > bMatches) return -1;
              if (aMatches < bMatches) return 1;
            
              return 0;
            });
            

          setProfiles(profilesList);
        } catch (error) {
          console.error('Error fetching profiles:', error);
        }
      };

      fetchProfiles();
    }
  }, [user]);

  const filteredProfiles = profiles.filter((profile) => {
    const term = searchTerm.toLowerCase();
    return (
      profile.name?.toLowerCase().includes(term) ||
      profile.university?.toLowerCase().includes(term) ||
      profile.degree?.toLowerCase().includes(term) ||
      profile.internshipLocation?.toLowerCase().includes(term) ||
      profile.preferredLocation?.toLowerCase().includes(term)
    );
  });
  const paragraphStyle = {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '1.8',
    color: '#555',
    marginBottom: '20px',
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <h2 style={{ textAlign: 'center', color: '#C33764' }}>Home</h2>
      <p style={paragraphStyle}>The list is sorted through the most relevant profiles based on your locations added.</p>
      <p style={paragraphStyle}>DocSwap strongly recommends that all users exercise caution and perform due diligence before engaging with other users.</p>

      {/* Search Bar */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by preferred location, university, degree, name, etc."
          style={{
            padding: '12px',
            width: '100%',
            maxWidth: '500px',
            borderRadius: '25px',
            border: '1px solid #ddd',
            fontSize: '16px',
            outline: 'none',
            boxSizing: 'border-box',
            margin: '0 auto',
          }}
        />
      </div>

      {/* Profiles */}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '20px' }}>
        {filteredProfiles.length > 0 ? (
          filteredProfiles.map((profile) => (
            <div
              key={profile.id}
              style={{
                border: '1px solid #ddd',
                borderRadius: '10px',
                padding: '20px',
                backgroundColor: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              }}
              onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
            >
              <h3 style={{ marginBottom: '10px', color: '#C33764' }}>{profile.name}</h3>
              <p><strong>University:</strong> {profile.university}</p>
              <p><strong>Degree:</strong> {profile.degree}</p>
              <p><strong>Current Location:</strong> {profile.internshipLocation}</p>
              <p><strong>Preferred Location:</strong> {profile.preferredLocation}</p>
              {profile.showEmail && <p><strong>Email:</strong> {profile.email}</p>}
              {profile.showPhone && <p><strong>Phone:</strong> {profile.phone}</p>}
              
            </div>
          ))
        ) : (
          <p style={{ textAlign: 'center', gridColumn: 'span 3', color: '#FF0000' }}>No profiles available. Please ensure you have added and saved your profile details to view other users' profiles.</p>
        )}
      </div>
    </div>
  );
}

export default Home;
