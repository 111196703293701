import React from 'react';
import { Link } from 'react-router-dom';

const aboutUsStyle = {
  backgroundColor: '#fff',
  color: '#333',
  fontFamily: "'Roboto', sans-serif",
  padding: '40px 20px',
  textAlign: 'center',
};

const headingStyle = {
  fontSize: '36px',
  fontWeight: 'bold',
  color: '#C33764', // Your logo pink color
  marginBottom: '20px',
};

const paragraphStyle = {
  fontSize: '18px',
  lineHeight: '1.6',
  color: '#555',
  marginBottom: '20px',
  maxWidth: '800px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const linkStyle = {
  color: '#C33764', // Pink color for links
  textDecoration: 'none',
  fontWeight: 'bold',
};

function About() {
  return (
    <div style={aboutUsStyle}>
      <h1 style={headingStyle}>About Us</h1>
      <p style={paragraphStyle}>
        DocSwap is a platform designed specifically for doctors who have graduated and are looking to swap their internship places with others across South Africa. Our goal is to simplify the process of finding and connecting with other doctors who are interested in exchanging internship locations, making the transition between different hospitals or clinics easier and more efficient.
      </p>
      <p style={paragraphStyle}>
        By using DocSwap, doctors can create profiles, share their internship details, and find others who are willing to swap positions based on mutual preferences. This platform helps increase the flexibility and accessibility of internship opportunities, while giving doctors the chance to move to different locations according to their personal or professional needs.
      </p>
      <p style={paragraphStyle}>
        Whether you’re looking for a change in your internship location, or if you just want to explore new opportunities, DocSwap is here to make it possible for you to find the right match.
      </p>

      <div style={{ marginTop: '30px' }}>
        <Link to="/Contact" style={linkStyle}>Contact Us</Link>
      </div>
    </div>
  );
}

export default About;
