import React from 'react';

const containerStyle = {
  backgroundColor: '#fff',
  color: '#333',
  fontFamily: "'Roboto', sans-serif",
  padding: '40px 20px',
  textAlign: 'left',
  maxWidth: '1000px',
  margin: '0 auto',
};

const headingStyle = {
  fontSize: '36px',
  fontWeight: 'bold',
  color: '#C33764',
  marginBottom: '20px',
};

const subHeadingStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginTop: '30px',
  color: '#333',
};

const paragraphStyle = {
  fontSize: '16px',
  lineHeight: '1.8',
  color: '#555',
  marginBottom: '20px',
};

function Privacy() {
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Privacy Policy</h1>

      <p style={paragraphStyle}>
        DocSwap values your privacy and is committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, and protect your information in accordance with South Africa's Protection of Personal Information Act (POPIA).
      </p>

      <h2 style={subHeadingStyle}>1. Information We Collect</h2>
      <p style={paragraphStyle}>
        1.1 When you use our platform, we collect personal information such as your name, email address, medical qualifications, internship details, and other information necessary for providing our services.
      </p>
      <p style={paragraphStyle}>
        1.2 We may also collect non-personal information such as IP addresses and usage data to improve the functionality and performance of our platform.
      </p>

      <h2 style={subHeadingStyle}>2. How We Use Your Information</h2>
      <p style={paragraphStyle}>
        2.1 Your personal information is used to provide you with the services you request, including the ability to swap internship placements with other doctors across South Africa.
      </p>
      <p style={paragraphStyle}>
        2.2 We may also use your information to communicate with you about updates, promotions, or other information related to DocSwap, but only if you opt in to receive such communications.
      </p>

      <h2 style={subHeadingStyle}>3. Data Protection and POPIA Compliance</h2>
      <p style={paragraphStyle}>
        3.1 DocSwap is fully committed to protecting your personal data in compliance with South Africa’s Protection of Personal Information Act (POPIA). 
      </p>
      <p style={paragraphStyle}>
        3.2 We will never sell, rent, or trade your personal data to third parties without your consent, except as required by law. We use industry-standard encryption methods to safeguard your information.
      </p>
      <p style={paragraphStyle}>
        3.3 You have the right to access, update, or delete your personal information at any time. Please contact us at <a href="mailto:docswap.sup@gmail.com">docswap.sup@gmail.com</a> to request any changes.
      </p>

      <h2 style={subHeadingStyle}>4. Sharing Your Information</h2>
      <p style={paragraphStyle}>
        4.1 We may share your information with trusted third parties who assist us in operating the platform, such as hosting providers or data processors, but only for the purpose of providing our services.
      </p>
      <p style={paragraphStyle}>
        4.2 We may also disclose your personal data if required by law, such as in response to legal requests or to protect our rights.
      </p>

      <h2 style={subHeadingStyle}>5. Cookies</h2>
      <p style={paragraphStyle}>
        5.1 DocSwap uses cookies to enhance your experience on our platform. Cookies are small files stored on your device that help us remember your preferences and improve the functionality of the site.
      </p>
      <p style={paragraphStyle}>
        5.2 You can control cookie settings through your browser, but please note that disabling cookies may affect the performance of the platform.
      </p>

      <h2 style={subHeadingStyle}>6. Data Retention</h2>
      <p style={paragraphStyle}>
        6.1 We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, or as required by law.
      </p>

      <h2 style={subHeadingStyle}>7. Your Rights</h2>
      <p style={paragraphStyle}>
        7.1 As per POPIA, you have the right to request access to the personal information we hold about you. You also have the right to request corrections or deletion of your information.
      </p>
      <p style={paragraphStyle}>
        7.2 To exercise your rights, please contact us at <a href="mailto:docswap.sup@gmail.com">docswap.sup@gmail.com</a>.
      </p>

      <h2 style={subHeadingStyle}>8. Security</h2>
      <p style={paragraphStyle}>
        8.1 We take the security of your personal data seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, alteration, or destruction.
      </p>

      <h2 style={subHeadingStyle}>9. Changes to This Privacy Policy</h2>
      <p style={paragraphStyle}>
        9.1 DocSwap reserves the right to update this Privacy Policy at any time. We will notify you of any significant changes by posting an updated version on this page.
      </p>

      <h2 style={subHeadingStyle}>10. Contact Information</h2>
      <p style={paragraphStyle}>
        10.1 If you have any questions or concerns regarding this Privacy Policy, please contact us at: <a href="mailto:docswap.sup@gmail.com">docswap.sup@gmail.com</a>
      </p>
    </div>
  );
}

export default Privacy;
